import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Container,
  Hero,
  Loader,
  Calendar,
  StaticError,
  StaticWarning,
  Box,
} from '@components';
import { CalendarContext } from '@helpers';
import { TECHNICAL_BREAK } from '@constants';
import MESSAGES from '@messages';

const TimetablePage = () => {
  const {
    events,
    setEvents,
    fromDate,
    pageconfig,
  } = useContext(CalendarContext);

  const { events_disabled, events_disabled_info, additional_info } = pageconfig || {};

  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    if (events_disabled === undefined || events_disabled === true) {
      setLoading(false);
      return;
    }

    setLoading(true);

    axios
      .get(`/agenda/${fromDate}`)
      .then(({ data, status }) => {
        if (status === 200) {
          setEvents(data);
          setLoading(false);
          setError('');
        } else {
          setLoading(false);
          setError(MESSAGES.DEFAULT_ERROR);
        }
      }).catch(() => {
        setLoading(false);
        setError(MESSAGES.DATABASE_ERROR);
      });
  }, [setEvents, events_disabled, fromDate]);


  const render = () => {
    if (isLoading) return <Loader />;
    if (error) return <StaticError width={[1, 1, 1, 1 / 2]}>{error}</StaticError>;
    if (events_disabled || TECHNICAL_BREAK) {
      return <StaticWarning width={[1, 1, 1, 1 / 2]}>{events_disabled_info || MESSAGES.TECHNICAL_BREAK}</StaticWarning>;
    }

    return (
      <>
        {additional_info && <StaticWarning width={[1, 1, 1, 1 / 2]}>{additional_info}</StaticWarning>}
        <Calendar events={events} />
      </>
    );
  };

  return (
    <Container textAlign="center">
      <Hero
        title={MESSAGES.SCHEDULE_TITLE}
        center
        seo={{
          metaTitle: MESSAGES.SCHEDULE_META_TITLE,
        }}
      />
      {render()}
    </Container>
  );
};

export default TimetablePage;
